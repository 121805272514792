import 'bootstrap-datepicker'
import 'slick-carousel'

import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.es.min.js'

function scroll_to_class(element_class, removed_height) {
  let scroll_to = $(element_class).offset().top - removed_height;
  if($(window).scrollTop() != scroll_to) {
    $('html, body').stop().animate({scrollTop: scroll_to}, 0);
  }
}

function bar_progress(progress_line_object, direction) {
  let number_of_steps = progress_line_object.data('number-of-steps');
  let now_value = progress_line_object.data('now-value');
  let new_value = 0;
  if(direction == 'right') {
    new_value = now_value + ( 100 / number_of_steps );
  }
  else if(direction == 'left') {
    new_value = now_value - ( 100 / number_of_steps );
  }
  progress_line_object.attr('style', 'width: ' + new_value + '%;').data('now-value', new_value);
}

function copy_values() {
  const root = $('[data-behavior="service-preview"]')

  // Copy vehicle image
  const vehicle_id = $('[name="service[vehicle_id]"]').val()
  const image_src = $(`[data-id="${vehicle_id}"]`).find('img').attr('src')
  $('[data-behavior="service-preview-image"]').attr('src', image_src)

  // Copy hours array
  const hours = $('[name="service[hours][]"]:checked').map((index, item) => item.value).get().join(', ')
  $('[data-behavior="service-preview-hours"]').text(hours)

  // Copy select value
  const kind_select = $('[name="service[kind]"]')
  const kind_value = kind_select.val()
  const kind = kind_select.find(`option[value="${kind_value}"]`).text()
  $('[data-behavior="service-preview-kind"]').text(kind)

  // Copy standard inputs
  root.find('[data-source]').each(function(index, element) {
    const source = $(element).data('source')
    const value = $(`[name="service[${source}]"]`).val()
    // console.log(source, value)
    $(element).text(value)
  })
}

export default function services() {
  const validate = (index, item) => {
    if ($(item).is(':valid')) {
      $(item).removeClass('input-error')
      return true
    } else {
      $(item).addClass('input-error')
      return false
    }
  }

  $('.f1').on('keypress', 'input', function(args) {
    if (args.keyCode == 13) {
      let parent_fieldset = $(this).parents('fieldset')
      let button = $(parent_fieldset).find('.btn-next')
      $(button).click()
      return false
    }
  })

  $('.f1 .btn-next').on('click', function() {
    let parent_fieldset = $(this).parents('fieldset')
    let current_active_step = $(this).parents('.f1').find('.pasos-registro.active')
    let progress_line = $(this).parents('.f1').find('.f1-progress-line')

    let inputs = parent_fieldset.find('input, select, textarea').filter(':visible')
    let is_valid = $.inArray(false, inputs.map(validate)) == -1

    if ( is_valid ) {
      parent_fieldset.fadeOut(400, function() {
        // change icons
        current_active_step.removeClass('active').addClass('activated').next().addClass('active')
        // progress bar
        bar_progress(progress_line, 'right')
        // copy values
        copy_values()
        // show next step
        $(this).next().fadeIn()
        // scroll window to beginning of the form
        scroll_to_class( $('.f1'), 20 )
        // focus first element
        $(this).next().find('input, select, textarea').filter(':visible:first').focus()
      })
    }

  })

  // previous step
  $('.f1 .btn-prev').on('click', function() {
    // navigation steps / progress steps
    let current_active_step = $(this).parents('.f1').find('.pasos-registro.active')
    let progress_line = $(this).parents('.f1').find('.f1-progress-line')

    $(this).parents('fieldset').fadeOut(400, function() {
      // change icons
      current_active_step.removeClass('active').prev().removeClass('activated').addClass('active')
      // progress bar
      bar_progress(progress_line, 'left')
      // show previous step
      $(this).prev().fadeIn()
      // scroll window to beginning of the form
      scroll_to_class( $('.f1'), 20 )
      // focus first element
      $(this).prev().find('input, select, textarea').filter(':visible:first').focus()
    })
  })

  $('#datepicker').datepicker({
    uiLibrary: 'bootstrap',
    format: "dd/mm/yyyy",
    autoclose: true,
    language: 'es'
  })

  $('.slider-for').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '.slider-nav'
  })

  const updateId = (event, slick, currentSlide) => {
    let slide = slick.$slides[currentSlide]

    let id = $(slide).find('[data-behavior="vehicle-id-container"]').data('id')

    $('[data-behavior="vehicle-id"]').val(id)
  }

  $('.slider-for').on('afterChange', updateId);

  $('.slider-nav').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: '.slider-for',
    dots: true,
    centerMode: true,
    focusOnSelect: true
  })
}
