import DataTable from './components/data_table'
import Select2 from './components/select2'
import Icheck from './components/icheck'

export function start() {
  init()
  DataTable.start()
  Select2.start()
  Icheck.start()
}

export function init() {
  let url = window.location.pathname

  $(`[href='${url}']`)
    .not("[data-method='post']")
    .not("[data-method='delete']")
    .parents('li')
    .addClass('active')
}
