// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/activestorage').start()

import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import 'jquery'
import 'bootstrap-sass'
import theme from '../src/public/theme.init'
import main from '../src/public/main'
import services from '../src/public/services'
import { init } from '../src/features'
import '../src/plugins/common.min.js'
import 'jquery.easing'
import 'jquery.appear'

import '@fortawesome/fontawesome-free/js/all'

import '../stylesheets/public'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

$(document).on('turbolinks:load', theme)
$(document).on('turbolinks:load', main)
$(document).on('turbolinks:load', services)
$(document).on('turbolinks:load', init)

Rails.start()
Turbolinks.start()
