import 'slicknav/dist/jquery.slicknav.js'
import 'owl.carousel'

export default function main() {
  let flag = false

  $(window).on('scroll', () => {
    let scroll = $(window).scrollTop()

    if(scroll > 0){
      if(!flag){
        $(".navbar").css({"background-color": "#fff"})
        $(".ul.menu li a").css({"color": "#000", "font-weight": "500"})
        flag = true
      }
    }
    else {
      if(flag){
        $(".navbar").css({"background-color": "rgba(0, 0, 0, 0.5)"})
        $(".ul.menu li a").css({"color": "#fff", "font-weight": "500"})
        flag = false
      }
    }
  });

  let lastScrollTop = 0
  $(window).on('scroll', () => {

    if ($(window).scrollTop() > 0) {
      $('.navbar').addClass('scrolled')
    }

    if ($(window).scrollTop() < 1) {
      $('.navbar').removeClass('scrolled')
    }
  })

  let hamburger = document.querySelector(".hamburger")
  hamburger.addEventListener("click", () => {
    hamburger.classList.toggle("is-active")
  })

  // MENÚ DESPLEGABLE
  $(".menu-desplegable").on('click', function() {
    $(this).children(".sub-menu").slideToggle()
  })

  $(".navbar-collapse").on('click', (pp) => {
    pp.stopPropagation()
  })

  let counters = $(".counter")
  let countersQuantity = counters.length
  let counter = []

  for (let i = 0; i < countersQuantity; i++) {
    counter[i] = parseInt(counters[i].innerHTML)
  }

  let count = (start, value, id) => {
    let localStart = start;
    setInterval(() => {
      if (localStart < value) {
        localStart++
        counters[id].innerHTML = localStart
      }
    }, 40)
  }

  for (let j = 0; j < countersQuantity; j++) {
    count(0, counter[j], j)
  }

  const tabControl = () => {
    $('.tabbed-content').find('.tabs').each(function(index, tab) {
      let visible = $(tab).is(':visible')

      if (visible) {
        $(tab).find('a').on('click', function(event){
          event.preventDefault()
          let target = $(this).attr('href'),
          tabs = $(this).parents('.tabs'),
          buttons = tabs.find('a'),
          item = tabs.parents('.tabbed-content').find('.item')
          buttons.removeClass('active')
          item.removeClass('active')
          $(this).addClass('active')
          $(target).addClass('active')
        })
      } else {
        $('.item').on('click', function(){
          let container = $(this).parents('.tabbed-content'),
          currId = $(this).attr('id'),
          items = container.find('.item')
          container.find('.tabs a').removeClass('active')
          items.removeClass('active')
          $(this).addClass('active')
          container.find('.tabs a[href$="#'+ currId +'"]').addClass('active')
        })
      }
    })
  }

  tabControl()
  let resizeTimer
  $(window).on('resize', (e) => {
    clearTimeout(resizeTimer)
    resizeTimer = setTimeout(() => tabControl(), 250)
  })

  $('.contenido-slider').each(function(){
    let bg = $(this).data('setbg')

    $(this).css('background-image', 'url(' + bg + ')')
  })

  /*------------------
    Slider
  --------------------*/
  let hero_s = $(".slider")
  hero_s.owlCarousel({
    loop: true,
    margin: 0,
    nav: true,
    items: 1,
    dots: true,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    navText: ['<i class="flaticon-left-arrow-1"></i>', '<i class="flaticon-right-arrow-1"></i>'],
    smartSpeed: 1200,
    autoHeight: false,
    autoplay: true,
    onInitialized: function() {
      let a = this.items().length
      $("#snh-1").html("<span>1</span><span>" + a + "</span>")
    }
  }).on("changed.owl.carousel", (a) => {
    let b = --a.item.index
    a = a.item.count
    $("#snh-1").html("<span> "+ (1 > b ? b + a : b > a ? b - a : b) + "</span><span>" + a + "</span>")
  })

  hero_s.append('<div class="slider-nav-warp"><div class="slider-nav"></div></div>')
  $(".slider .owl-nav, .slider .owl-dots").appendTo('.slider-nav')

  $(window).on("load", () => {
    $('#announcement').modal()
  })
}
