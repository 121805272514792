import 'datatables.net'
import 'datatables.net-bs'

export default class DataTable {
  static LANGUAGE_URL = "//cdn.datatables.net/plug-ins/1.10.22/i18n/Spanish.json"

  static SELECTOR = '[role="datatable"]'

  static start() {
    const elements = $(this.SELECTOR)

    for (let element of elements) {
      new DataTable(element)
    }
  }

  constructor(element) {
    this.element  = element
    this.url      = element.dataset.url
    this.csrf     = $('meta[name=csrf-token]').attr("content")

    this.bindEvents()
    this.build()
  }

  bindEvents() {
    this.build = this.build.bind(this)
  }

  build() {
    $(this.element).DataTable({
      ajax: {
        url: this.url,
        type: 'POST',
        data: {
          authenticity_token: this.csrf
        }
      },
      language: {
        url: this.__proto__.constructor.LANGUAGE_URL
      },
      createdRow: this.handleCreatedRow,
      drawCallback: this.handleDraw
    })

    this.element.style.width = "100%"
  }

  handleCreatedRow(row, data, dataIndex) {
    let id = data[0]
    row.setAttribute('data-id', `elements_${id}`)
  }

  handleDraw(settings) {}
}
